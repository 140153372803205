<template>
    <div class="image-container">
        <img
            :src="loadedImage ? imagePath : placeholder"
            class="centered-image"
            @load="onImageLoaded"
            v-if="isVisible"
        />
    </div>
</template>

<script>
export default {
    name: 'ImagesGuide',
    props: {
        imageName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
            loadedImage: false,
            placeholder: require('@/assets/quick-guide/placehold.png'),
        };
    },
    computed: {
        imagePath() {
            return require(`@/assets/quick-guide/bulk-ordering-step${this.imageName}.png`);
        },
    },
    mounted() {
        this.observeImage();
    },
    methods: {
        observeImage() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.isVisible = true;
                        observer.unobserve(entry.target);
                    }
                });
            });
            observer.observe(this.$el);
        },
        onImageLoaded() {
            this.loadedImage = true;
        },
    },
};
</script>

<style scoped>
.centered-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em auto;
    border: 1px solid lightslategray;
}
.image-container {
    width: 100%;
    height: auto;
}
</style>
